@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.minicart_MiniCart__1-uQ4 {
  padding-top: 40px;
  padding-left: 40px;
}
.minicart_miniCartContainer__2XoL1 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  cursor: pointer;
}
.minicart_miniCartContainer__2XoL1 .minicart_icon__LhcVz {
  background-color: #da2937;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.minicart_miniCartContainer__2XoL1 .minicart_cartItems__3rqWV {
  flex: 2 1;
  border: 1px solid #d9d9d9;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.minicart_miniCartContainer__2XoL1 .minicart_cartItems__3rqWV p {
  padding: 0;
  margin: 0;
  padding-left: 12px;
}
.minicart_miniCartContainer__2XoL1 .minicart_cartItems__3rqWV .minicart_title__2CAwu {
  font-weight: bold;
  padding-top: 1px;
}

.login_loginBtn__2cf-W {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  background: ;
  border: 0;
  cursor: pointer;
}
.login_loginBtn__2cf-W svg {
  margin-right: 2px;
}
.login_logoutBtn__2AUTR {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
}
.login_logoutBtn__2AUTR svg {
  font-size: 13px;
  margin-left: 2px;
}
.login_row__ba8Kz {
  margin-bottom: 5px;
}

.megamenu_container__1gqHX {
  max-width: 1366px;
  margin: 0 auto;
}
nav {
  height: 50px;
}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  text-align: left;
}
nav li {
  display: inline-block;
}
nav a,
nav button {
  color: #fff;
  text-decoration: none;
  display: block;
  height: 50px;
  padding: 0.75em 1em;
  font-size: 15px;
  border-radius: 0;
  background: none;
  cursor: pointer;
  border: none;
}
nav a .megamenu_expandIcon__V7D-A,
nav button .megamenu_expandIcon__V7D-A {
  margin-left: 6px;
}
nav button span {
  margin-top: -2px;
}
nav li:hover {
  background: #444;
}
nav li:hover a,
nav li:hover button {
  color: #fff;
}
.megamenu_menuSub__11tZq {
  position: absolute;
  left: 0;
  background: #fff;
  border: 1px solid grey;
  width: 100%;
  display: none;
  color: #fff;
  padding: 24px;
  z-index: 999;
}
nav li:hover .megamenu_menuSub__11tZq {
  display: block;
}
.megamenu_menuSub__11tZq li {
  display: block;
}
.megamenu_menuSub__11tZq a:hover,
.megamenu_menuSub__11tZq button:hover {
  text-decoration: underline;
}
.megamenu_menuCategory__2cuGh {
  padding: 5px !important;
  padding-left: 8px !important;
  background-color: #da2937 !important;
  margin-bottom: 0.3em;
  height: 35px;
  text-align: left;
  width: 100%;
  border-radius: 0;
  color: #fff;
  font-size: 15px;
}
.megamenu_menuCategory__2cuGh:hover span {
  text-decoration: underline;
}
.megamenu_menuCategory__2cuGh:first-of-type {
  margin-top: 0;
}
.megamenu_menuSub__11tZq a,
.megamenu_menuSub__11tZq button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.megamenu_listMenus__2hLJr {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
  text-align: left;
}
.megamenu_listMenus__2hLJr a,
.megamenu_listMenus__2hLJr button {
  color: #000;
  text-decoration: none;
  display: block;
  height: 25px;
}
.megamenu_listMenus__2hLJr a span,
.megamenu_listMenus__2hLJr button span {
  font-size: 14px;
}
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo a,
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo button {
  color: #444;
  text-decoration: none;
  display: block;
  height: 25px;
  padding-left: 4px;
}
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo a span,
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo button span {
  font-size: 14px;
}
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo a .megamenu_newMarker__1U5SY,
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo button .megamenu_newMarker__1U5SY {
  text-transform: uppercase;
  padding-left: 4px;
  color: #da2937;
  background: #ffffff;
  font-size: 10px;
  text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc, 1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee, 4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc, 4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee, 7px 6px 1px #cccccc;
  position: relative;
  top: -3px;
}
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo a:hover {
  color: #da2937;
}
.megamenu_listMenus__2hLJr .megamenu_listMenusItems__3kxWo button:hover {
  color: #da2937;
}
.megamenu_menuCol1__3ELAQ {
  width: 25%;
}
.megamenu_menuCol2__PiPQv {
  width: 50%;
}

.productloading_productLoadingContainer__2h1g2 {
  border: 1px solid #ddd;
  margin-bottom: 25px;
}
.productloading_productLoadingContainer__2h1g2 .productloading_boxImage__3kzNI {
  width: 100%;
  padding-top: 100%;
}
.productloading_productLoadingContainer__2h1g2 .productloading_name__3nxin {
  height: 18px;
  margin: 15px 0;
  margin-left: 15px;
  margin-right: 15px;
}
@-webkit-keyframes productloading_placeHolderShimmer__3t5v2 {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes productloading_placeHolderShimmer__3t5v2 {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.productloading_animated-background__1dNQG,
.productloading_productLoadingContainer__2h1g2 .productloading_boxImage__3kzNI,
.productloading_productLoadingContainer__2h1g2 .productloading_name__3nxin {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: productloading_placeHolderShimmer__3t5v2;
          animation-name: productloading_placeHolderShimmer__3t5v2;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

.productloadinglist_name__13QtN {
  height: 28px;
  margin-bottom: 20px;
  max-width: 400px;
  margin-top: 20px;
}
@-webkit-keyframes productloadinglist_placeHolderShimmer__30xaR {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes productloadinglist_placeHolderShimmer__30xaR {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.productloadinglist_animated-background__1xgpB,
.productloadinglist_name__13QtN {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: productloadinglist_placeHolderShimmer__30xaR;
          animation-name: productloadinglist_placeHolderShimmer__30xaR;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

.productlist_name__3zCxg {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}
.productlist_noProducts__LKScs {
  font-size: 14px;
  height: 300px;
}
.productlist_floatingCart__2mGar {
  position: fixed;
  right: 50px;
  top: 45%;
  z-index: 200;
  cursor: pointer;
  padding: 8px;
}
.productlist_floatingCart__2mGar .productlist_icon__1JM-3 {
  font-size: 34px;
  color: #da2937;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf', GradientType=0);
  background-color: #e8ecf1;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 1px solid #ccc;
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 24px;
  border-radius: 100%;
  text-shadow: 0px 1px 0px #ffffff;
  box-shadow: 2px 2px 2px 0px #999999;
}
.productlist_floatingCart__2mGar .productlist_icon__1JM-3:hover {
  background: linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed', GradientType=0);
  background-color: #dfdfdf;
  color: #da2937;
}
.productlist_floatingCart__2mGar .productlist_text__2n72L {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 4px;
}
@media (max-width: 1366px) {
  .productlist_floatingCart__2mGar {
    top: 65%;
  }
}

.productview_viewProduct__2tlTa {
  padding: 25px;
}
.productview_viewProduct__2tlTa .page-title {
  padding: 0;
}
.productview_spinner__Vp5nZ {
  margin-top: 75px;
  margin-bottom: 75px;
}
.productview_imgGallery__3GiU8 {
  margin-top: 20px;
}
.productview_stockStatus__1jEP1 {
  font-size: 16px;
  font-weight: bold;
  color: #da2937;
}
.productview_stockStatus__1jEP1.productview_ARRIVING_SOON__2XCy0 {
  color: #42447f;
}
.productview_stockStatus__1jEP1.productview_IN_STOCK__2qosJ {
  color: #03a678;
}
.productview_priceBox__1yv1Z {
  display: inline-block;
}
.productview_priceBox__1yv1Z .price-box {
  text-align: left;
  padding-left: 10px;
}
.productview_addInfo__2wRgH {
  font-size: 12px;
  font-style: italic;
}
.productview_addInfo__2wRgH .productview_infoContainer__2o9zm {
  display: flex;
}
.productview_addInfo__2wRgH .productview_infoContainer__2o9zm > div {
  padding: 0 3px 5px 3px;
}
.productview_addInfo__2wRgH .productview_infoContainer__2o9zm > div:nth-child(1) {
  font-weight: bold;
  flex: 5% 1;
}
.productview_addInfo__2wRgH .productview_infoContainer__2o9zm > div:nth-child(2) {
  flex: 95% 1;
}

.product_productContainer__lDX9T {
  border: 1px solid #ddd;
  margin-bottom: 25px;
}
.product_productContainer__lDX9T .product_boxImage__xIg1- {
  position: relative;
  width: 100%;
}
.product_productContainer__lDX9T .product_boxImage__xIg1- imgLink {
  display: block;
}
.product_productContainer__lDX9T .product_boxImage__xIg1- img {
  max-width: 100%;
}
.product_productContainer__lDX9T .product_boxImage__xIg1- .product_quickViewBtn__jba_Q {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 104px;
  height: 32px;
  margin-left: -52px;
  margin-top: -16px;
  display: none;
}
.product_productContainer__lDX9T .product_boxImage__xIg1-:hover .product_quickViewBtn__jba_Q {
  display: block;
}
.product_productContainer__lDX9T .product_details__2iS3w {
  padding-left: 20px;
  padding-right: 20px;
}
.product_productContainer__lDX9T .product_details__2iS3w .product_name__1WArs {
  height: 60px;
  padding: 5px 0;
  overflow-y: hidden;
  text-align: center;
}
.product_productContainer__lDX9T .product_details__2iS3w .product_name__1WArs a {
  color: #444;
  line-height: 1.5em;
  font-weight: bold;
  font-size: 14px;
}
.product_stockStatus__1bWWG {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 39px;
  margin-bottom: 40px;
}
.product_stockStatus__1bWWG.product_ARRIVING_SOON__35ACy {
  color: #42447f;
}
.product_stockStatus__1bWWG.product_OUT_OF_STOCK__3YQ5t {
  color: #da2937;
}
.product_stockStatus__1bWWG.product_IN_STOCK__3GGBP {
  color: #42447f;
}
.product_newIcon__1ZWF7,
.product_specialPriceIcon__ioVQ6,
.product_tag__2MI-T {
  position: absolute;
  max-width: 150px !important;
  top: -8px;
}
.product_newIcon__1ZWF7 {
  left: -8px;
}
.product_specialPriceIcon__ioVQ6,
.product_tag__2MI-T {
  right: -12px;
}
.product_addInfo__1mDJn {
  font-size: 12px;
  font-style: italic;
}
.product_addInfo__1mDJn .product_infoContainer__3D5No {
  display: flex;
}
.product_addInfo__1mDJn .product_infoContainer__3D5No > div {
  padding: 0 3px 5px 3px;
}
.product_addInfo__1mDJn .product_infoContainer__3D5No > div:nth-child(1) {
  font-weight: bold;
  flex: 20% 1;
}
.product_addInfo__1mDJn .product_infoContainer__3D5No > div:nth-child(2) {
  flex: 80% 1;
}

.attribute_attributeBox__EjdDG {
  margin-top: 20px;
  margin-bottom: 20px;
}
.attribute_attributeBox__EjdDG .attribute_attrLink__1Ez5O {
  display: block;
}
.attribute_attributeBox__EjdDG .attribute_attrLink__1Ez5O .attribute_attrImg__2G-5t {
  max-width: 100%;
}
.attribute_attributeBox__EjdDG .attribute_attrLink__1Ez5O .attribute_title__2aVWP {
  text-align: center;
  padding-top: 16px;
  font-size: 15px;
}


.filters_title__Ol23A {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #444;
}
.filters_filterTitle__2iCDY {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.filters_filterCheckbox__3RTpO {
  margin: 0;
  line-height: 30px;
  display: block;
  margin-left: 8px;
}
.filters_refineBtnContainer__1UawO {
  margin-top: 12px;
  margin-bottom: 24px;
}
.filters_refineBtnContainer__1UawO .filters_refineBtn__1-nfq {
  float: right;
}
.filters_filterOptions__2m80E {
  max-height: 400px;
  overflow-y: auto;
}


.spinner_spining__2H9C8 {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
}

.cartsummary_summaryCard__1VNht {
  margin-bottom: 20px;
}
.cartsummary_summaryCard__1VNht > div:first-child {
  font-weight: bold;
}
.cartsummary_summaryCard__1VNht .cartsummary_shippingAlert__3QtRz {
  margin-bottom: 16px;
}
.cartsummary_summaryCard__1VNht .cartsummary_shippingAlert__3QtRz span {
  font-size: 12px;
}
.cartsummary_summaryCard__1VNht .cartsummary_summaryTable__3S0-K {
  margin-bottom: 16px;
  border-top: 1px solid #e8e8e8;
}
.cartsummary_summaryCard__1VNht .cartsummary_summaryTable__3S0-K table tr:last-child td {
  font-weight: bold;
}
.cartsummary_summaryCard__1VNht .cartsummary_summaryTable__3S0-K .cartsummary_summaryValue__wHtyn {
  text-align: right;
}
.cartsummary_summaryCard__1VNht .cartsummary_summaryTable__3S0-K .cartsummary_summaryValue__wHtyn .cartsummary_shippingCharges__26qaq {
  font-weight: bold;
  color: #42447f;
}

.container {
  padding: 20px;
}
.addContainer .addressTitle {
  padding: 5px;
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.addContainer .addName {
  font-weight: bold;
}
.addContainer .addLine,
.addContainer .addName {
  display: block;
  width: 100%;
  padding: 5px;
}

.imagegallery_imageGallery__1iXt1 {
  width: 100%;
}
.imagegallery_activeImage__gA6LI img {
  max-width: 100%;
  border: 1px solid #ddd;
}
.imagegallery_thumbnails__3iv65 img {
  width: 123px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
}
.imagegallery_thumbnails__3iv65 .imagegallery_active__1PJ4k {
  border: 1px solid #42447f;
}

.pricebox_priceBox__1omxA {
  text-align: center;
  margin-bottom: 10px;
}
.pricebox_priceBox__1omxA .pricebox_regularPrice__3B83n,
.pricebox_priceBox__1omxA .pricebox_specialPrice__3Xrda {
  font-size: 16px;
  color: #e74c3c;
}
.pricebox_priceBox__1omxA .pricebox_oldPrice__8q7FY {
  font-size: 14px;
  color: #444;
  text-decoration: line-through;
  padding-right: 4px;
}
.pricebox_priceBox__1omxA .pricebox_bulkPricing__3hMeJ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 8px;
}
.pricebox_priceBox__1omxA .pricebox_bulkPricing__3hMeJ :first-child {
  background: #eee;
}
.pricebox_priceBox__1omxA .pricebox_bulkPricing__3hMeJ .pricebox_bulkPricingUnit__2beZO {
  flex: 1 1 auto;
}
.pricebox_priceBox__1omxA .pricebox_bulkPricing__3hMeJ .pricebox_bulkPricingUnit__2beZO div {
  padding: 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  font-weight: bold;
}
.pricebox_priceBox__1omxA .pricebox_bulkPricing__3hMeJ .pricebox_bulkPricingUnit__2beZO .pricebox_qty__2SajH {
  background: #eee;
}
.pricebox_addToCartBox__2Rq-D {
  text-align: center;
  margin-bottom: 20px;
}
.pricebox_addToCartBox__2Rq-D .pricebox_qtyForm__2InAe .pricebox_itemRow__3DiK7 {
  margin-right: 0;
}
.pricebox_addToCartBox__2Rq-D .pricebox_addToCartSpinner__2u9H- {
  width: 75px;
}
.pricebox_addToCartBox__2Rq-D .pricebox_addToCartBtn__3zeg8 {
  margin-left: 5px;
}
.pricebox_addToCartBox__2Rq-D .pricebox_addToCartBtn__3zeg8:hover {
  background-color: #da2937 !important;
  color: #fff !important;
}
.pricebox_addToCartBox__2Rq-D .pricebox_addToCartBtn__3zeg8 svg {
  margin-right: 10px;
}
.pricebox_alreadyInCart__30Bt4 {
  color: green;
  line-height: 2.5em;
  font-weight: bold;
}

.carousel_carousel__Ao203 .carousel_slickslide__73TjH {
  text-align: center;
  width: 100%;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.carousel_carousel__Ao203 .carousel_slickslide__73TjH h3 {
  color: #fff;
}
.carousel_slideImg__2ntM3 {
  width: 100%;
}
.slick-prev,
.slick-next {
  display: none !important;
}

.displayField_displayField__2kgHC {
  padding-left: 4px;
}
.displayField_displayField__2kgHC .displayField_label__3Nl69 {
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.displayField_displayField__2kgHC .displayField_value__20mal {
  height: 20px;
  font-size: 13px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.table_filterButton__1_9W_ {
  display: inline-block;
}

.customerForm_sectionHeader__1mJzN {
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}
.customerForm_hyperLink__1EWD4 {
  color: #da2937;
}

.phoneslider_latestPhones__4jcVn {
  padding: 16px;
}
.phoneslider_phone__1tgt3 {
  width: 160px;
  height: 200px;
  float: left;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}
.phoneslider_title__2o80m {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  padding-left: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: '\2190';
}
[dir='rtl'] .slick-prev:before {
  content: '\2192';
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: '\2192';
}
[dir='rtl'] .slick-next:before {
  content: '\2190';
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\2022';
  width: 20px;
  height: 20px;
  font-family: 'slick';
  font-size: 25px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.navbar_menuBar__xCdVF {
  background: #d9d9d9;
  min-height: 48px;
  padding: 8px 16px;
  margin-bottom: 8px;
}
.navbar_menuBar__xCdVF .navbar_title__ua-9T {
  margin: 0;
}
.navbar_menuBar__xCdVF .navbar_actions__oi-MU .navbar_actionsItems__1bFPB {
  text-align: right;
}
.navbar_menuBar__xCdVF .navbar_actions__oi-MU .navbar_actionsItems__1bFPB > * {
  margin: 0 5px;
}
.navbar_menuBar__xCdVF .navbar_actions__oi-MU .navbar_actionsItems__1bFPB a,
.navbar_menuBar__xCdVF .navbar_actions__oi-MU .navbar_actionsItems__1bFPB button {
  color: gray;
  background-color: #eeee;
}
.navbar_menuBar__xCdVF .navbar_actions__oi-MU .navbar_actionsItems__1bFPB > * {
  margin-left: 8px;
}

.screenLoader_screenLoader__1dfx6 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  padding-top: 250px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}
.screenLoader_screenLoader__1dfx6 .fa-spin {
  font-size: 55px;
}

.login_loginBtn__3HjM7 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  background: ;
  border: 0;
  cursor: pointer;
}
.login_loginBtn__3HjM7 svg {
  margin-right: 2px;
}
.login_logoutBtn__3tum- {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
}
.login_logoutBtn__3tum- svg {
  font-size: 13px;
  margin-left: 2px;
}
.login_row__3QivH {
  margin-bottom: 5px;
}

.header_blinkText__3Egc1 {
  -webkit-animation-name: header_blinker__3qtQ6;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: header_blinker__3qtQ6;
  -moz-animation-duration: 4s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  animation-name: header_blinker__3qtQ6;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  color: red;
  background: #f5f5f5;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
@-webkit-keyframes header_blinker__3qtQ6 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes header_blinker__3qtQ6 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.header_topNoticeBar__39z9X {
  text-align: center;
  padding: 5px;
}
.header_accountInfoButtonsContainer__Sil7L {
  text-align: right;
  height: 34px;
}
.header_accountInfoBar__3z4bb {
  background-color: #f5f5f5;
}
.header_accountInfoButtons__1qHL0 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}
.header_accountInfoButtons__1qHL0 li {
  float: left;
}
.header_logo__2h2GS {
  height: 128px;
  text-align: right;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.header_logo__2h2GS img {
  max-height: 100%;
  max-width: 100%;
}
.header_search__1qgi5 {
  padding-top: 40px;
}
.header_navigationMenu__2bFT5 {
  background-color: #656565;
  height: 50px;
}
.header_accountBtn__2n8kZ {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
  background: #da2937;
  color: #fff;
}
.header_accountBtn__2n8kZ svg {
  margin-right: 2px;
}
.header_accountBtn__2n8kZ:hover {
  color: #ccc;
}

.footer_footer__1o-a7 {
  font-size: 13px;
}
.footer_newsletterSubscribe__2qLqk {
  background-color: #f5f5f5;
  height: 80px;
  text-align: center;
}
.footer_newsletterSubscribe__2qLqk .footer_formItem__1tx_4 {
  padding-top: 20px;
}
.footer_newsletterSubscribe__2qLqk .footer_formItem__1tx_4 .footer_subscribeText__1VCng {
  font-size: 21px;
}
.footer_newsletterSubscribe__2qLqk .footer_formItem__1tx_4 .footer_subscribeBtn__mxkNG svg {
  margin-right: 5px;
}
.footer_footerCopyright__3F55k {
  background-color: #656565;
  color: #fff;
  text-align: center;
  padding: 10px;
}
.footer_sitemap__3EBT4 {
  background-color: #eee;
  padding: 20px;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw {
  padding-left: 20px;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw h3 {
  color: #444;
  font-size: 14px;
  font-weight: bold;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul {
  list-style: none;
  padding: 10px 0 0;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul li {
  padding-bottom: 7px;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul li a {
  color: #555;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul li a svg {
  margin-right: 5px;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul li a:hover {
  color: #333333;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul.footer_contactDetails__2JD0D li {
  line-height: 23px;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul.footer_contactDetails__2JD0D li p {
  float: left;
}
.footer_sitemap__3EBT4 .footer_sitemapcol__YK_Aw ul.footer_contactDetails__2JD0D li svg {
  float: left;
  font-size: 21px;
  margin-right: 7px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app_backTopBtn__3WVzB .ant-back-top-content {
  background: #656565;
}
@media (max-width: 1366px) {
  .app_backTopBtn__3WVzB {
    display: none;
  }
}

.widgets_title__aSNjF {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}
.widgets_img__13thf {
  max-width: 100%;
}
.widgets_staticImagesContainer__3BTZ- {
  margin-bottom: 25px;
}

.homepage_alertMsg__5_SCB {
  margin-top: 20px;
}
.homepage_carousel__W1sZH .homepage_slickslide__1ejXJ {
  text-align: center;
  height: 160px;
  width: 100%;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.homepage_carousel__W1sZH .homepage_slickslide__1ejXJ h3 {
  color: #fff;
}

.devices_devicesBox__3xblA {
  text-align: center;
  padding-bottom: 15px;
  min-height: 400px;
}

.device_deviceBox__yPMIf {
  margin-top: 20px;
  margin-bottom: 20px;
}
.device_deviceBox__yPMIf .device_deviceLink__Ye2Cl {
  display: block;
}
.device_deviceBox__yPMIf .device_deviceLink__Ye2Cl .device_deviceImg__RQ8Wp {
  max-width: 100%;
}
.device_deviceBox__yPMIf .device_deviceLink__Ye2Cl .device_title__2j-iP {
  text-align: center;
  padding-top: 16px;
  font-size: 15px;
}

.devicebrowser_deviceBrowserBox__13QxY {
  margin-bottom: 32px;
}
.devicebrowser_deviceGroupBox__bbf5S {
  margin: 32px 0;
}
.devicebrowser_yearsBox__2_DLb span.ant-tag {
  font-size: 18px;
  padding: 8px 20px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
}
.devicebrowser_yearsBox__2_DLb .devicebrowser_deselectMark__Q40sF {
  font-size: 16px;
  margin-right: 6px;
  visibility: visible;
}
.devicebrowser_yearsBox__2_DLb .devicebrowser_deselectMark__Q40sF.devicebrowser_not_selected__3qv9d {
  visibility: hidden;
}



.viewcart_updateQtyBtn__3UkR- {
  background: #f5f5f5;
  margin-left: 5px;
}
.viewcart_centeredColName__1F-iB {
  text-align: center !important;
}
.viewcart_emptyCart__BaJ5- {
  margin: 15px;
  font-size: 16px;
  padding-bottom: 100px;
}
.viewcart_itemsTable__kbX1y {
  margin-bottom: 32px;
}
.viewcart_itemsTable__kbX1y tbody > tr > td {
  padding: 8px;
}
.viewcart_itemsTable__kbX1y thead tr th div {
  font-weight: bold;
}
.viewcart_globalErrors__TpoC_ {
  margin-bottom: 16px;
}
.viewcart_globalErrorsList__KctVO {
  margin: 0;
}
.viewcart_clearCartBtn__gLRWv {
  display: inline;
  float: left;
  width: 180px;
  z-index: 990;
  margin-bottom: 16px;
}
.viewcart_removedItemsAlert__2y6TP {
  margin-bottom: 16px;
}
.viewcart_tooltipImage__1f4P3 {
  max-width: 200px;
}

.steps_checkoutSteps__1jVHK {
  margin: 20px;
  padding: 20px;
}

.shipping_shippingCheckout__1AiJG {
  margin-top: 30px;
}
.shipping_shippingAlert__3MivY {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shipping_methodName__3YJEl,
.shipping_methodDesc__2epVa,
.shipping_methodCharges__3T3Yq {
  width: 225px;
  display: inline-block;
  padding-left: 15px;
}
.shipping_methodCharges__3T3Yq {
  color: #42447f;
  font-weight: bold;
}
.shipping_methodRadio__3mYK_ {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid #ccc;
}
.shipping_methodRadio__3mYK_ > span:first-child {
  margin-left: 15px;
}
.shipping_addressItem__1WDKO .shipping_addressRadioGroup__1HNyc {
  display: block;
}
.shipping_addressRadio__zQudu {
  padding: 25px;
  width: 100%;
  border: 2px solid #eee;
  margin-top: 20px;
}
.shipping_addressRadio__zQudu.ant-radio-wrapper-checked {
  border: 2px solid #42447f;
}
.shipping_addressRadio__zQudu.ant-radio-wrapper-checked .shipping_addSelectedIcon__3r5ip {
  display: block;
}
.shipping_addressRadio__zQudu .shipping_addSelectedIcon__3r5ip {
  color: #42447f;
  font-size: 28px;
  position: absolute;
  top: -3px;
  right: 0px;
  display: none;
}
.shipping_addressRadio__zQudu > span:first-child {
  display: none;
}
.shipping_addressRadio__zQudu > span:last-child {
  display: block;
}
.shipping_addName__3PoSJ {
  font-weight: bold;
}
.shipping_addLine__RicX0,
.shipping_addName__3PoSJ {
  display: block;
  width: 100%;
  padding: 5px;
}
.shipping_addNewAddBtnContainer__3D_J7 {
  text-align: right;
}
.shipping_addressItem__1WDKO div.ant-form-explain,
.shipping_methodItem__Dl7hL div.ant-form-explain {
  background: #42447f;
  color: #fff;
  padding: 5px;
  margin-top: 5px;
}

.checkout_sectionHeading__2dRvx {
  font-size: 22px;
  display: block;
  color: #333;
  padding-bottom: 12px;
  border-bottom: 2px solid #ccc;
}
.checkout_actionBtnContainer__1lzWe {
  text-align: right;
  margin-bottom: 150px;
  margin-top: 20px;
}
.checkout_actionBtnContainer__1lzWe .checkout_nextBtn__1AN1n {
  width: 150px;
}

.payment_paymentCheckout__2gYEY {
  margin-top: 30px;
}
.payment_methodName__fvwr5,
.payment_methodDesc__RWdSL {
  width: 225px;
  display: inline-block;
  padding-left: 15px;
}
.payment_methodDesc__RWdSL {
  width: 400px;
}
.payment_methodRadio__3LFMA {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid #ccc;
}
.payment_methodRadio__3LFMA > span:first-child {
  margin-left: 15px;
}
.payment_addressItem__3aASO .payment_addressRadioGroup__3LfZv {
  display: block;
}
.payment_addressRadio__1NH04 {
  padding: 25px;
  width: 100%;
  border: 2px solid #eee;
  margin-top: 20px;
}
.payment_addressRadio__1NH04.ant-radio-wrapper-checked {
  border: 2px solid #42447f;
}
.payment_addressRadio__1NH04.ant-radio-wrapper-checked .payment_addSelectedIcon__R6OT3 {
  display: block;
}
.payment_addressRadio__1NH04 .payment_addSelectedIcon__R6OT3 {
  color: #42447f;
  font-size: 28px;
  position: absolute;
  top: -3px;
  right: 0px;
  display: none;
}
.payment_addressRadio__1NH04 > span:first-child {
  display: none;
}
.payment_addressRadio__1NH04 > span:last-child {
  display: block;
}
.payment_addName__NbVwf {
  font-weight: bold;
}
.payment_addLine__2UH7L,
.payment_addName__NbVwf {
  display: block;
  width: 100%;
  padding: 5px;
}
.payment_addNewAddBtnContainer__2raG4 {
  text-align: right;
}
.payment_addressItem__3aASO div.ant-form-explain,
.payment_methodItem__1vaXq div.ant-form-explain {
  background: #42447f;
  color: #fff;
  padding: 5px;
  margin-top: 5px;
}

.confirm_paymentCheckout__24So4 {
  margin-top: 30px;
  font-size: 1.25em;
}
.confirm_paymentCheckout__24So4 h3 {
  margin-top: 30px;
}
.confirm_viewCart__3SSCS {
  margin-right: 10px;
}
.confirm_method__CaKZ- > span,
.confirm_addName__3vD1Y {
  font-weight: bold;
}

.success_iconContainer__OJewA {
  text-align: center;
  font-size: 65px;
  color: green;
  margin-bottom: 30px;
  margin-top: 40px;
}
.success_msgContainer__1AVYE {
  text-align: center;
  font-size: 23px;
  line-height: 1.9;
  margin-bottom: 20px;
}
.success_btnContainer__25Ynh {
  text-align: center;
  margin-bottom: 60px;
}


.account\.home_container__37KeW {
  padding: 20px;
}
.account\.home_addressViewContainer__1QXH4 {
  margin-bottom: 32px;
}
.account\.home_addNewAddBtn__G9bS- {
  margin-bottom: 32px;
}
.account\.home_editAddBtn__9qL8Y {
  margin-top: 12px;
}
.account\.home_deleteBtn__34a-5 {
  margin-top: 12px;
  margin-left: 12px;
}

.orderview_table__2oeW2 {
  width: 100%;
}
.orderview_table__2oeW2 .orderview_row__3Halq {
  height: 30px;
  border-bottom: 1px solid grey;
}
.orderview_table__2oeW2 .orderview_row__3Halq .orderview_product__2RYY5 {
  text-align: left;
  font-weight: bold;
}
.orderview_table__2oeW2 .orderview_row__3Halq .orderview_number__3Z71E {
  text-align: right;
}
.orderview_table__2oeW2 .orderview_row__3Halq .orderview_total__1-1HK {
  text-align: right;
  font-weight: bold;
}
.orderview_table__2oeW2 .orderview_row__3Halq .orderview_header_number__1giQf {
  text-align: right;
}
.orderview_orderTitle__3U6iq {
  padding: 10px;
  border-bottom: 5px solid #f4a137;
}
.orderview_header__1xlat {
  background-color: grey;
}
.orderview_containt__3L1aD {
  padding: 20px !important;
}

.account_page__1fF-w .account_pageTitle__2VKDs {
  padding-top: 20px;
  font-size: 166.6%;
  color: #444;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  border-bottom: 5px solid #f4a137;
}


.invoiceview_table__PDF5d {
  width: 100%;
}
.invoiceview_table__PDF5d .invoiceview_row__hz2qK {
  height: 30px;
  border-bottom: 1px solid #eee;
}
.invoiceview_table__PDF5d .invoiceview_row__hz2qK .invoiceview_product__2jqu7 {
  padding-left: 16px;
  text-align: left;
  font-weight: bold;
}
.invoiceview_table__PDF5d .invoiceview_row__hz2qK .invoiceview_number__2KE2c {
  text-align: right;
}
.invoiceview_table__PDF5d .invoiceview_row__hz2qK .invoiceview_total__2PewS {
  padding-right: 16px;
  text-align: right;
  font-weight: bold;
}
.invoiceview_table__PDF5d .invoiceview_row__hz2qK .invoiceview_header__2hAJ2 {
  padding: 16px;
}
.invoiceview_table__PDF5d .invoiceview_row__hz2qK .invoiceview_header_number__3joDJ {
  padding: 16px;
  text-align: right;
}
.invoiceview_orderTitle__3KEv2 {
  border-bottom: 5px solid #f4a137;
}

.pageview_container__ly9vO {
  max-width: 1366px;
  margin: 0 auto;
}
.pageview_container__ly9vO .pageview_title__2v_3r {
  padding: 20px;
  font-weight: bold;
}
.pageview_container__ly9vO .pageview_content__3k3Iy {
  padding-left: 20px;
  font-size: 14px;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.login_loginBtn__3Rku5 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  background: ;
  border: 0;
  cursor: pointer;
}
.login_loginBtn__3Rku5 svg {
  margin-right: 2px;
}
.login_logoutBtn__3NwwT {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
}
.login_logoutBtn__3NwwT svg {
  font-size: 13px;
  margin-left: 2px;
}
.login_row__1_Ptj {
  margin-bottom: 5px;
}
.login_container__3Ol1M {
  padding: 20px;
}

.error_errorPage__2EFEu {
  text-align: center;
}
.error_icon__158x6 {
  font-size: 55px;
  color: #da2937;
  margin-top: 30px;
  margin-bottom: 20px;
}
.error_message__3KQbh {
  font-size: 22px;
  margin-bottom: 20px;
}
.error_btn__jmGFa {
  margin-bottom: 30px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #666;
}
.search-input > span.ant-input-suffix {
  right: 0;
}
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.cuiContentWrapper {
  max-width: 1366px;
  margin: 0 auto;
}
.clear-float {
  float: none;
  clear: both;
  display: block;
}
.page-title {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  color: #444;
  padding: 12px;
  margin-top: 15px;
}
a:focus {
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-carousel .slick-dots li button {
  height: 0px;
}
.ant-carousel .slick-dots-bottom {
  bottom: -12px;
}

