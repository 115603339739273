.container {
  max-width: 1366px;
  margin: 0 auto;
}

nav {
  height: 50px;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  text-align: left;
}

nav li {
  display: inline-block;
}

nav a,
nav button {
  color: #fff;
  text-decoration: none;
  display: block;
  height: 50px;
  padding: 0.75em 1em;
  font-size: 15px;
  border-radius: 0;
  background: none;
  cursor: pointer;
  border: none;

  .expandIcon {
    margin-left: 6px;
  }
}

nav button span {
  margin-top: -2px;
}
nav li:hover {
  background: #444;
}

nav li:hover a,
nav li:hover button {
  color: #fff;
}

.menuSub {
  position: absolute;
  left: 0;
  background: #fff;
  border: 1px solid grey;
  width: 100%;
  display: none;
  color: #fff;
  padding: 24px;
  z-index: 999;
}

nav li:hover .menuSub {
  display: block;
}

.menuSub li {
  display: block;
}

.menuSub a:hover,
.menuSub button:hover {
  text-decoration: underline;
}

.menuCategory {
  padding: 5px !important;
  padding-left: 8px !important;
  background-color: @primary-color !important;
  margin-bottom: 0.3em;
  height: 35px;
  text-align: left;
  width: 100%;
  border-radius: 0;
  color: #fff;
  font-size: 15px;
}
.menuCategory:hover span {
  text-decoration: underline;
}

.menuCategory:first-of-type {
  margin-top: 0;
}

.menuSub a,
.menuSub button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.listMenus {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
  text-align: left;
  a,
  button {
    color: #000;
    text-decoration: none;
    display: block;
    height: 25px;
    span {
      font-size: 14px;
    }
  }
  .listMenusItems {
    a,
    button {
      color: #444;
      text-decoration: none;
      display: block;
      height: 25px;
      padding-left: 4px;
      span {
        font-size: 14px;
      }
      .newMarker {
        text-transform: uppercase;
        padding-left: 4px;
        color: @primary-color;
        background: #ffffff;
        font-size: 10px;
        text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc, 1px 2px 1px #eeeeee,
          3px 2px 1px #cccccc, 2px 3px 1px #eeeeee, 4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc,
          4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee, 7px 6px 1px #cccccc;
        position: relative;
        top: -3px;
      }
    }
    a:hover {
      color: @primary-color;
    }
    button:hover {
      color: @primary-color;
    }
  }
}

.menuCol1,
.menuCol2,
.menuCol3,
.menuCol4 {
  //  float: left;
}

.menuCol1 {
  width: 25%;
}

.menuCol2 {
  width: 50%;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;